@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;


@layer base {
  [type='text'],
  [type='email'],
  [type='url'],
  [type='password'],
  [type='number'],
  [type='date'],
  [type='datetime-local'],
  [type='month'],
  [type='search'],
  [type='tel'],
  [type='time'],
  [type='week'],
  [multiple],
  textarea,
  select {
    @apply bg-white dark:bg-neutral-900 border-neutral-200 dark:border-neutral-700 rounded-full focus:ring-indigo-500 
    focus:border-indigo-500 block w-full sm:text-sm text-neutral-900 dark:text-neutral-200;
  }
}

.datepicker::-webkit-calendar-picker-indicator {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23bbbbbb" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
}

/* table header styles */
.ant-table-thead > tr > th {
  @apply bg-slate-700 dark:bg-indigo-900 !important;
}

/* table rows styles */
.ant-table-tbody > tr {
  @apply bg-slate-600 dark:bg-neutral-900 !important;
}

/* table rows hover styles */
.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  @apply bg-slate-500 dark:bg-neutral-800 !important;
}

.ant-picker {
  background: transparent !important;
}

.ant-picker-input input {
  @apply text-black dark:text-white !important;
}

.ant-input::placeholder {
  @apply text-gray-400 dark:text-gray-600 !important;
}

.ant-picker-input::placeholder {
  @apply text-gray-400 dark:text-gray-600 !important;
}

.ant-select-selection-search-input:focus {
  border-color: transparent !important;
  box-shadow: none !important;
}

.ant-select-selection-search-input {
  @apply text-black dark:text-white !important;
}

.ant-table-pagination {
  @apply bg-slate-700 rounded-md p-2 w-full !important;
}

.PhoneInput input {
  @apply rounded-2xl h-11
}